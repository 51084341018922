import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mq } from '../breakpoints'

export const Drawer = styled.nav`
  background: ${(props) => props.theme.palette.primary.main};
  border-right: 1px solid ${(props) => props.theme.palette.primary.contrastText};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 42px 30px 30px 12px;
  position: fixed;
  top: 0;
  left: -400px;
  transition: all 0.3s ease;
  z-index: 2;

  ${mq.md(css`
    padding: 30px;
  `)}
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  display: block;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
`

export const drawerVisible = css`
  left: 0px;
  ${mq.xs(css`
    left: 0px;
  `)}
`

function NavDrawer({ close, links, visible }) {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = visible
      ? 'hidden'
      : 'auto'
  }, [visible])

  return (
    <Drawer css={[visible && drawerVisible]} data-testid="nav-drawer">
      {links.map((link) => (
        <StyledLink key={link.name} onClick={close} to={link.slug}>
          {link.name}
        </StyledLink>
      ))}
    </Drawer>
  )
}

export default NavDrawer
