import { css, SerializedStyles } from '@emotion/react';

export type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export const breakpointOrder = ['xs', 'sm', 'md', 'lg', 'xl'];
export interface BreakpointBounds {
  min: number;
  max?: number;
}

export const breakpoints: { [k in BreakpointName]: BreakpointBounds } = {
  xl: { min: 1536 },
  lg: { max: 1535, min: 1200 },
  md: { max: 1199, min: 900 },
  sm: { max: 899, min: 620 },
  xs: { max: 619, min: 0 },
};

export const mq = Object.keys(breakpoints).reduce((accumulator: StringKeyable, label: BreakpointName) => {
  const prefix = typeof breakpoints[label] === 'string' ? '' : 'min-width:';
  const suffix = typeof breakpoints[label] === 'string' ? '' : 'px';
  accumulator[label] = (cls: SerializedStyles) =>
    css`
      @media (${prefix + breakpoints[label].min + suffix}) {
        ${cls};
      }
    `;

  return accumulator;
}, {}) as { [key in BreakpointName]: (s: SerializedStyles) => SerializedStyles };
