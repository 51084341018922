import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { CssBaseline } from '@mui/material';

import Navbar from './Navbar';
import Footer from './Footer';

import * as styles from './layout.styles';

interface PropTypes {
  children: React.ReactNode;
  pageName: string;
}

const Layout = ({ children, pageName }: PropTypes) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        allContentfulNavbar {
          edges {
            node {
              links {
                name
                slug
              }
            }
          }
        }
        allContentfulSite {
          edges {
            node {
              description
              keywords
              title
            }
          }
        }
        contentfulPerson(lastName: { eq: "Dougherty" }, firstName: { eq: "Julia" }) {
          facebook
        }
      }
    `}
    render={(data) => (
      <React.Fragment>
        <Helmet>
          <html lang='en' />
          <title>{data.allContentfulSite.edges[0].node.title}</title>
          <meta content='index, follow' name='robots' />
          <meta name='description' content={data.allContentfulSite.edges[0].node.description} />
          <meta name='keywords' content={data.allContentfulSite.edges[0].node.keywords} />
        </Helmet>
        <CssBaseline />
        <Navbar links={data.allContentfulNavbar.edges[0].node.links} owner={data.allContentfulSite.edges[0].node} />
        <styles.Wrapper>
          <styles.HeaderCurveWrapper>
            <styles.HeaderCurve />
          </styles.HeaderCurveWrapper>
          <styles.Content>{children}</styles.Content>
          <styles.FooterCurveWrapper>
            <styles.FooterCurve />
          </styles.FooterCurveWrapper>
        </styles.Wrapper>
        <Footer
          facebook={data.contentfulPerson.facebook}
          links={data.allContentfulNavbar.edges[0].node.links}
          pageName={pageName}
        />
      </React.Fragment>
    )}
  />
);

export default Layout;
