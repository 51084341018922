import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { rgba } from 'polished';

import { Page } from '../types/Page';
import { mq } from '../breakpoints';

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const linkStyle = css`
  font-size: 10px;
  opacity: 0.8;
  letter-spacing: 1.1px;
  padding: 0 4px;
  text-align: middle;
  text-decoration: none;
  text-transform: uppercase;

  ${mq.sm(css`
    font-size: 12px;
    padding: 0 12px;
  `)}

  ${mq.md(css`
    font-size: 14px;
  `)}
`;

const StyledFooter = styled.footer`
  background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.7)};
  height: 48px;
  position: relative;
`;

interface PropTypes {
  facebook: string;
  links: Page[];
  pageName: string;
}

function Footer({ facebook, links, pageName }: PropTypes) {
  const footerLinks = links.filter((page) => pageName !== page.name);
  return (
    <FooterWrapper>
      <StyledFooter>
        <Grid container alignItems='center' justifyContent='center' py={1}>
          {footerLinks.map((link) => (
            <Grid item key={link.name}>
              <Link css={linkStyle} to={link.slug}>
                {link.name}
              </Link>
            </Grid>
          ))}
        </Grid>
      </StyledFooter>
    </FooterWrapper>
  );
}

export default Footer;
